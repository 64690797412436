import { createContext, useState } from "react";

export const NodesContext = createContext({
  nodeEditorSaved: false,
  setNodeEditorSaved: () => {},
});

export const NodesContextProvider = ({ children }) => {
  const [nodeEditorSaved, setNodeEditorSaved] = useState(false);

  return (
    <NodesContext.Provider value={{ nodeEditorSaved, setNodeEditorSaved }}>
      {children}
    </NodesContext.Provider>
  );
};
