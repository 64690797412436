import React, { useState } from 'react';
import { Input, Row, Col, Form, Button, Switch, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';

const getMaxKey = (data) => {
  let maxKey = 0;
  data.forEach((obj) => {
    if (!isNaN(obj.key)) {
      const key = parseInt(obj.key, 10);
      maxKey = Math.max(maxKey, key) + 1;
    } else {
      const keyNumber = parseInt(maxKey, 10);
      maxKey = isNaN(keyNumber) ? 0 : keyNumber + 1;
    }
  });
  return maxKey;
};

const OptionKeyValue = ({ type, value, onChange,campo }) => {
  const [options, setOptions] = useState(value || []);
  const [jsonview, setJsonView] = useState(typeof value === 'object' || (typeof value === 'string' && value.includes('$')));
  const limits = {
    "switch": 20,
    "optionButton":3
  }
  const maxLengths = {
    "switch": 20,
    "optionButton":3,
    "setVariable":100
  }

  const limit = limits[type] || 40;
  const maxLength = maxLengths[type] || 40;

  const handleInputChange = (key, newValue, field) => {
    if (jsonview && (typeof value === 'object' )) {
      let updatedValue = [...options];
      updatedValue[key][field] = newValue;
      onChange(campo,updatedValue);
      setOptions(updatedValue);
    } else {
      onChange(campo,newValue);
      setOptions([newValue]);
    }
  };

  const handle_resetValue = () =>{
    let aux = [{"key":"variable","value":null}]
    setJsonView(true)
    onChange(aux);
    setOptions(aux);
  }
  const handleView = () => {
    setJsonView(!jsonview);
  };

  const handleIncrease = () => {
    const aux = getMaxKey(options);
    const updatedValue = [...options, { key: `${aux}`, value: 'New value' }];
    onChange(updatedValue);
    setOptions(updatedValue);
  };

  const handleDelete = (key) => {
    const finalValue = options.filter((item) => item.key !== key);
    onChange(finalValue);
    setOptions(finalValue);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title={'Reset values'}>
           <Button onClick={()=>handle_resetValue()} size={'small'} style={{ alignSelf: 'flex-start' }}><Icons.ReloadOutlined /></Button>
        </Tooltip>
       
        <Switch
          style={{ justifyContent: 'end' ,marginRight:'20px',minWidth:'60px'}}
          checkedChildren="Pretty"
          unCheckedChildren="Text"
          defaultChecked={jsonview}
          onChange={handleView}
        />
      </div>
      {(jsonview && typeof value === "object") ? (
        Object.entries(options).map(([key, elemento], index) => (
          <Row key={index}>
            <Col span={5}>
              <Form.Item labelCol={{ span: 24 }} name={`no_save_key_${elemento.key}`} initialValue={elemento.key} label={index === 0 ? (campo==="variables")?'Fact':'Key' : ''}>
                <Input value={key} placeholder="Please enter a value" onBlur={(e) => handleInputChange(key, e.target.value, 'key')} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item labelCol={{ span: 24 }} name={`no_save_title_${elemento.key}`} initialValue={elemento.value} label={index === 0 ? 'Value' : ''}>
                <Input showCount={true} maxLength={maxLength} value={elemento.value} placeholder="Please enter a value" onBlur={(e) => handleInputChange(key, e.target.value, 'value')} />
              </Form.Item>
            </Col>
            <Col span={2}>
              {options.length > 1 ? (
                <Form.Item labelCol={{ span: 24 }} style={{ lineHeight: '22px' }} name={`no_save_delete_${index}`} label={index === 0 ? ' ' : ''}>
                  <Tooltip title="Eliminar elemento">
                    <Button size="small" type="primary" shape="circle" onClick={() => handleDelete(elemento.key)}>
                      <Icons.RestOutlined />
                    </Button>
                  </Tooltip>
                </Form.Item>
              ) : ''}
            </Col>
          </Row>
        ))
      ) : (
        <>
            <Form.Item labelCol={{ span: 24 }} name={`options_texto`} initialValue={(typeof value === 'object') ? JSON.stringify(value) : JSON.stringify(value).replace(/"/g, '')} label={'Manual value'}>
                <Input showCount={true} maxLength={255} placeholder="Please enter a value" onChange={(e) => handleInputChange('options', e.target.value, 'value')}/>
            </Form.Item>
          {(typeof value === 'object') && JSON.stringify(value)}
        </>
      )}
      {((typeof value === 'object') && jsonview && (options.length >= 0 && options.length < limit)) ? (
        <Row>
          <Col span={2} offset={21}>
            <Tooltip title="Añadir elemento">
              <Button size="small" type="primary" shape="circle" onClick={handleIncrease}>
                <Icons.PlusOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default OptionKeyValue;



// import React , {useState} from 'react';
// import { Input, Row, Col, Form, Button, Switch, Tooltip } from 'antd';
// import * as Icons from '@ant-design/icons';
// 
// const getMaxKey = (data) => {
//           let maxKey = 0;
//           data.forEach((obj) => {
//             if (!isNaN(obj.key)) {
//               const key = parseInt(obj.key, 10);
//               maxKey = Math.max(maxKey, key)+1;
//             } else {
//               const keyNumber = parseInt(maxKey, 10);
//               maxKey = isNaN(keyNumber) ? 0 : keyNumber + 1;
//             }
//           });
//           return maxKey;
// };
// 
// const OptionKeyValue = ({ type,  value, onChange }) => {
//           const [options, setOptions] = useState(value||[]);
//           console.log("typeof value ",typeof value )
//           const [jsonview, setjsonview] = useState(typeof value === "object" || (typeof value === "string" && value.includes("$")));
//           const limit = (type==='switch')?20:(type==='optionButton')?3:10
// 
//           const handleInputChange = (key, newValue, field) => {
//              if ((value).includes("$") ||  (newValue).includes("$") ||  (typeof newValue ==="string")) {
//                     let  updatedValue = value;
//                     onChange(updatedValue);
//                     setOptions(updatedValue);          
//              } else {
//                     let  updatedValue = { ...value };
//                     updatedValue[key][field] = newValue;
//                     const finalValue = Object.keys(value).map((key) => value[key]);
//                     onChange(finalValue);
//                     setOptions(finalValue);              
//              }
// 
//           };
// 
//           const handleView = () => {
//                     setjsonview(!jsonview);
//           };
// 
//           const handleIncrease = () => {
//                     const finalValue = Object.keys(value).map((key) => value[key]);
//                     let aux = getMaxKey(finalValue)
//                     finalValue.push({"key":''+aux,"value":"New value"})
//                     onChange(finalValue);
//                     setOptions(finalValue);
//           }
//           const handleDelete = (key) => {
//                     //const updatedValue = { ...value };
//                     //delete updatedValue[key];
//                     const finalValue = options.filter((item) => item.key !== key);
//                     onChange(finalValue);
//                     setOptions(finalValue);
//           };
//   return (
//                     <>
//                     <div style={{ display: 'flex', justifyContent: 'end' }}>
//                               <Switch disabled={typeof value !== "object" && value.includes("$")} style={{ marginLeft: '10px' }} checkedChildren="Pretty" unCheckedChildren="Text" defaultChecked={!(typeof value !== "object" && value.includes("$"))} onChange={handleView} />
//                     </div>
//                     {(jsonview && typeof value === "object" )? 
//                     Object.entries(options).map(([key, elemento], index) => (
//                               <Row key={index} >
//                                         <Col span={5}>
//                                                   <Form.Item  labelCol={{ span: 24 }} name={`no_save_key_${elemento.key}`} initialValue={elemento.key} label={(index===0)?"Key":''} >
//                                                             <Input value={key} placeholder="Please enter a value" onChange={(e) => handleInputChange(key, e.target.value, 'key')} />
//                                                   </Form.Item>
//                                         </Col>
//                                         <Col span={16}>
//                                                   <Form.Item  labelCol={{ span: 24 }} name={`no_save_title_${elemento.key}`} initialValue={elemento.value} label={(index===0)?"Value":''} >
//                                                             <Input showCount={true} maxLength= {20} value={elemento.value} placeholder="Please enter a value" onChange={(e) => handleInputChange(key, e.target.value, 'value')} />
//                                                   </Form.Item>
//                                         </Col>
//                                         <Col span={2}>
// 
//                                                   {options.length > 1 ? ( 
//                                                             <Form.Item  labelCol={{ span: 24 }} style={{'lineHeight':'10px'}} name={`no_save_delete_${index}`} label={(index === 0) ? ' ' : null}>
//                                                                       <Tooltip title="Eliminar elemento">
//                                                                                 <Button   size="small" type="primary" shape="circle" onClick={() => handleDelete(elemento.key)}><Icons.RestOutlined /></Button> 
//                                                                       </Tooltip>
//                                                             </Form.Item>
//                                                   ) : null}
// 
//                                         </Col>
//                               </Row>
//                     ))
//                     : 
//                     <>
//                     <Form.Item  labelCol={{ span: 24 }} name={`options_texto`} initialValue={((typeof value === "object" ))?JSON.stringify(value):JSON.stringify(value).replace(/"/g, '')} label={'Manual value'} >
//                       <Input showCount={true} maxLength= {255}  placeholder="Please enter a value" onChange={(e) => handleInputChange('options', e.target.value, 'value')} />
//                     </Form.Item>
// 
//                     {(typeof value === "object" ) && JSON.stringify(value)}
//                     </>
//                     }
//                     {((typeof value === "object" ) && jsonview && (options.length >= 0 && options.length < limit)) ? ( 
//                               <Row>
//                                         <Col span ={2} offset={21} >
//                                                   <Tooltip title="Añadir elemento">
//                                                             <Button  size="small" type="primary" shape="circle" onClick={() => handleIncrease()}><Icons.PlusOutlined /></Button> 
//                                                   </Tooltip>
//                                         </Col> 
//                               </Row>
//                     ) : null}
//                     </>
//   );
// };
// 
// export default OptionKeyValue;
