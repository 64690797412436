import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  MarkerType,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "reactflow";

import { SaveOutlined } from "@ant-design/icons";

import "reactflow/dist/style.css";
import {
  ConnectionLine,
  BuilderLibrary,
  BlockConditional,
  BlockStart,
  BlockGeneric,
  BlockEnd,
  BlockHttpRequest,
  BlockHttpRequestSimple,
  BlockImage,
  BlockOption,
  BlockinputText,
  BlockMessage,
} from "./BuilderLibrary";

import NodeEdge from "./NodeEdge";
import EdgeEditor from "./EdgeEditor";

import { Layout, Button, Spin } from "antd";

import Sider from "antd/es/layout/Sider";

import "./BuilderLibrary.css";
import { useParams } from "react-router-dom";
import { showSuccessNotification } from "../Services/notifications";
import { API_Request, fetchAndSerializeFlow } from "./../Services/APIService";
import { NodesContext } from "../contexts/NodesContext";

const nodeTypes = {
  start: BlockStart,
  log: BlockGeneric,
  message: BlockMessage,
  conditional: BlockConditional,
  switch: BlockOption,
  inputText: BlockinputText,
  inputDate: BlockGeneric,
  inputNumber: BlockGeneric,
  setVariable: BlockGeneric,
  setAtribute: BlockGeneric,
  optionButton: BlockOption,
  optionList: BlockOption,
  image: BlockImage,
  file: BlockGeneric,
  audio: BlockGeneric,
  video: BlockGeneric,
  sticker: BlockImage,
  location: BlockGeneric,
  document: BlockGeneric,
  HttpRequest: BlockHttpRequest,
  HttpRequestSimple: BlockHttpRequestSimple,
  goto: BlockGeneric,
  end: BlockEnd,
};

const edgeTypes = {
  buttonedge: NodeEdge,
};

const edgeOptions = {
  animated: false,
  type: "smoothstep",
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 12,
    height: 12,
    color: "#99a1e5",
  },
  style: { strokeWidth: 2, stroke: "#99a1e5" },
};

function hsvToHex(h, s, v) {
  // Verifica si los valores no son números o están fuera de rango
  if (
    typeof h !== "number" ||
    typeof s !== "number" ||
    typeof v !== "number" ||
    h < 0 ||
    h > 360 ||
    s < 0 ||
    s > 1 ||
    v < 0 ||
    v > 1
  ) {
    console.error("Valores HSV no válidos:", h, s, v);
    return "#000000"; // Valor predeterminado en caso de error
  }

  h = ((h % 360) + 360) % 360;
  s = Math.min(1, Math.max(0, s));
  v = Math.min(1, Math.max(0, v));

  const c = v * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v - c;

  let r, g, b;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  const intR = Math.round((r + m) * 255);
  const intG = Math.round((g + m) * 255);
  const intB = Math.round((b + m) * 255);

  const hexR = intR.toString(16).padStart(2, "0");
  const hexG = intG.toString(16).padStart(2, "0");
  const hexB = intB.toString(16).padStart(2, "0");

  return `#${hexR}${hexG}${hexB}`;
}

export const Builder = () => {
  const { id } = useParams();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(null);
  const [isEdgeEditorVisible, setEdgeEditorVisible] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [copiedNodes, setCopiedNodes] = useState(null);
  const { nodeEditorSaved } = useContext(NodesContext);

  const getSetFlow = async () => {
    try {
      // Hacer la solicitud fetch para obtener la estructura de nodos y edges
      const { serializedNodes, serializedEdges } = await fetchAndSerializeFlow({
        id,
      });
      setNodes(serializedNodes);
      setEdges(serializedEdges);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFlow = async () => {
    try {
      if (reactFlowInstance) {
        const flow = reactFlowInstance.toObject();
        // actualizar nodos en backend y db
        await API_Request("PUT", `/bots/nodes/${id}`, flow);
        showSuccessNotification("All data saved!!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const handleEdgeEditorClose = () => {
    setEdgeEditorVisible(false);
  };

  const handleEdgeEditorSave = (updateData) => {
    const h = updateData.color?.metaColor?.originalInput?.h || 0; // Valor predeterminado 0 si no está definido
    const s = updateData.color?.metaColor?.originalInput?.s || 0;
    const v = updateData.color?.metaColor?.originalInput?.v || 0;
    let color_aux = hsvToHex(h, s, v) || "#99a1e5";
    const updatedEdge = {
      ...updateData,
      label: updateData.label,
      markerEnd: {
        type: "arrowclosed",
        height: 12,
        width: 12,
        color: color_aux,
      },
      style: { stroke: color_aux },
    };
    delete updatedEdge.color;
    const updatedEdges = edges.map((item) =>
      item.id === updateData.id ? updatedEdge : item
    );
    console.log("updateData0", updatedEdge);
    setEdges(updatedEdges);
    setEdgeEditorVisible(false);
  };

  const handleEdgeEditorChange = (updateData) => {
    const h = updateData.color?.metaColor?.originalInput?.h || 0; // Valor predeterminado 0 si no está definido
    const s = updateData.color?.metaColor?.originalInput?.s || 0;
    const v = updateData.color?.metaColor?.originalInput?.v || 0;
    let color_aux = hsvToHex(h, s, v) || "#99a1e5";
    const updatedEdge = {
      ...updateData,
      label: updateData.label,
      markerEnd: {
        type: "arrowclosed",
        height: 12,
        width: 12,
        color: color_aux,
      },
      style: { stroke: color_aux },
    };
    delete updatedEdge.color;
    const updatedEdges = edges.map((item) =>
      item.id === updateData.id ? updatedEdge : item
    );
    setEdges(updatedEdges);
  };

  const onEdgeClick = (event, edge_aux) => {
    event.stopPropagation(); // Evita que el evento de clic se propague al flujo
    const updatedEdge = { ...edge_aux, type: "step", animated: true };

    const updatedEdges = edges.map((item) =>
      item.id === edge_aux.id ? updatedEdge : item
    );
    setEdges(updatedEdges);
    setSelectedEdge(updatedEdge);
    setEdgeEditorVisible(true);
  };

  const onEdgeMouseEnter = (event, edge_aux) => {
    event.stopPropagation();
    const updatedEdge = { ...edge_aux, animated: true };
    const updatedEdges = edges.map((item) =>
      item.id === edge_aux.id ? updatedEdge : item
    );
    setEdges(updatedEdges);
  };

  const onEdgeMouseLeave = (event, edge_aux) => {
    event.stopPropagation();
    const updatedEdge = { ...edge_aux, animated: false };
    const updatedEdges = edges.map((item) =>
      item.id === edge_aux.id ? updatedEdge : item
    );
    setEdges(updatedEdges);
  };

  const onSave = useCallback(saveFlow, [id, reactFlowInstance]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodesDelete = useCallback(
    async (deleted) => {
      try {
        await API_Request("DELETE", `bots/nodes/${deleted[0].id}`);
        await getSetFlow();
      } catch (e) {
        console.log("No es posible borrar");
      }

      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [setEdges, edges, nodes]
  );

  const postNode = async (node) => {
    try {
      const newNode = node;
      const result = await API_Request("POST", `/bots/nodes/${id}`, node);
      newNode.id = "" + result.data.id;
      setNodes((nds) => nds.concat(newNode));
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const node = JSON.parse(
        event.dataTransfer.getData("application/flow-iteractions")
      );

      showSuccessNotification("Element Added!");
      node.name = "New " + type;
      // check if the dropped element is valid

      try {
        if (
          !type ||
          (type === "start" &&
            nodes.find((element) => element.type === "start")?.type ===
              "start") ||
          (type === "end" &&
            nodes.find((element) => element.type === "end")?.type === "end")
        ) {
          console.log("NO");
          return false;
        }
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        const newNode = {
          node_type: type,
          type: type,
          position,
          data: { label: `${type} node`, type: type, payload: node },
        };
        postNode(newNode);
      } catch (e) {
        console.log(e);
      }
    },
    [id, nodes, reactFlowInstance, setNodes]
  );

  const handleKeyDown = useCallback(
    async (event) => {
      // fire only if its a node
      if (
        document.activeElement.classList.contains("react-flow__node") ||
        document.activeElement.classList.contains("react-flow__nodesselection-rect")
      ) {
        if (event.ctrlKey || event.metaKey) {
          switch (event.key) {
            case "c":
              const selectedNodes = nodes.filter((node) => node.selected);
              console.log(selectedNodes);
              if (selectedNodes) {
                setCopiedNodes(selectedNodes);
              }
              break;
            case "v":
              if (copiedNodes) {
                for await (const copiedNode of copiedNodes) {
                  const newNode = {
                    ...copiedNode,
                    node_type: copiedNode.type,
                    position: {
                      x: copiedNode.position.x + 50,
                      y: copiedNode.position.y + 50,
                    },
                    positionAbsolute: {
                      x: copiedNode.position.x + 50,
                      y: copiedNode.position.y + 50,
                    },
                    selected: false,
                  };
                  // remove ids so it doesnt throw error in backend when creating
                  delete newNode.id;
                  delete newNode.data.id;
                  await postNode(newNode);
                }
              }
              break;
            default:
              break;
          }
        }
      }
    },
    [nodes, copiedNodes]
  );

  useEffect(() => {
    getSetFlow();
  }, [id, setEdges, setNodes]);

  useEffect(() => {
    saveFlow();
    getSetFlow();
  }, [nodeEditorSaved]);

  if (!nodes) {
    return (
      <Spin
        spinning={TextTrackCue}
        style={{ position: "fixed", right: 16, bottom: 16 }}
      />
    );
  }

  return (
    <Layout style={{ minHeight: "90vh" }}>
      <Sider
        theme="light"
        width={"130"}
        className={"scroll-pretty"}
        style={{ overflow: "auto", height: "90vh" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <BuilderLibrary></BuilderLibrary>
      </Sider>
      <div style={{ height: "90vh", width: "100%" }}>
        <ReactFlowProvider>
          <ReactFlow
            ref={reactFlowWrapper}
            defaultEdgeOptions={edgeOptions}
            deleteKeyCode={["Delete"]}
            elements={nodes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgeDoubleClick={onEdgeClick}
            onEdgeMouseEnter={onEdgeMouseEnter}
            onEdgeMouseLeave={onEdgeMouseLeave}
            onNodesDelete={onNodesDelete}
            onConnect={onConnect}
            proOptions={{ hideAttribution: true }}
            style={{ background: "#f7f7f7" }}
            fitView
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            snapToGrid={true}
            connectionLineComponent={ConnectionLine}
            fitViewOptions={{ padding: 0.1, minZoom: 0.2, maxZoom: 2 }}
            onKeyDown={handleKeyDown}
          >
            <Controls showInteractive={true}>
              <Button
                style={{ padding: "5px", backgroundColor: "#ffffff" }}
                type="text"
                onClick={onSave}
              >
                <SaveOutlined />
              </Button>
            </Controls>
            <MiniMap style={{ height: 100 }} zoomable pannable />
            <Background variant="lines" gap={30} size={5} />
          </ReactFlow>
        </ReactFlowProvider>
        {isEdgeEditorVisible && (
          <EdgeEditor
            onSave={handleEdgeEditorSave}
            onClose={handleEdgeEditorClose}
            onChange={handleEdgeEditorChange}
            data={selectedEdge}
          />
        )}
      </div>
    </Layout>
  );
};
