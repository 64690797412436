import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RenderLayout from "./Components/Layout";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import AccountsPage from "./Pages/Account";
import SessionsPage from "./Pages/Sessions";
import UsersPage from "./Pages/Users";
import FlowsPage from "./Pages/Flows";
import { Builder } from "./Components/Builder";
import { ProtectedRoute } from "./Components/ProtectedRoute";
import ProfilePage from "./Pages/Profile";
import CustomersPage from "./Pages/Customers";
import BotsPage from "./Pages/Bots";
import { NodesContextProvider } from "./contexts/NodesContext";
export const GlobalConfig = {
  API_BACKEND: process.env.REACT_APP_API_BACKEND,
  NODE_ENV: process.env.REACT_APP_NODE_ENV || "PRO",
};
const App = () => {
  return (
    <NodesContextProvider>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<RenderLayout />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/accounts" element={<AccountsPage />} />
            <Route path="/accounts/:id" element={<AccountsPage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/customers/:customerid" element={<CustomersPage />} />
            <Route path="/sessions" element={<SessionsPage />} />
            <Route path="/sessions/:id" element={<SessionsPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/:id" element={<UsersPage />} />
            <Route path="/flows" element={<FlowsPage />} />
            <Route path="/flows/:id" element={<FlowsPage />} />
            <Route path="/flows/:module/:id" element={<FlowsPage />} />
            <Route path="/bots/builder/:id" element={<Builder />} />
            <Route path="/bots" element={<BotsPage />} />
            <Route path="/bots/:id" element={<BotsPage />} />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </NodesContextProvider>
  );
};
export default App;
