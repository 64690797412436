import React, { useEffect, useState,useContext } from 'react';
import { useParams } from 'react-router-dom';
import { API_Request } from '../Services/APIService';
import { SettingOutlined } from '@ant-design/icons';
import DataTable from '../Components/DataTable';
import { FormBuilder } from '../Components/FormBuilder';
import { Spin, Row, Col } from 'antd';

import { AuthContext } from './../Services/AuthService';

import FileUploader from './../Components/FileUploader';
import { FormSecondaryWindow } from './../Components/FormSecondaryWindow';

const dataTable_params = {
  "entity": "customers",
  "main": "customerId",
  "key": "customerId",
  "rows": 100,
  "fieldsForm":[
    {"name":"customerId","label":"customerId","required":true},
    {"name":"idAccount","label":"Account"},
    {"name":"attributes"}
  ],
  "actions": [
    {
      icon: <SettingOutlined />,
      link: "customers",
      key:"customerId"
    }
  ]
};
const handleUpload = (data) => {
  data.processedData.map((element) => {
    return API_Request('POST',  '/customers',{
      "idAccount": data.idAccount,
      "customerId":element.customerId,
      "attributes": element.attributes
    } )
    .then((result)=>{

    })
  })
}

const CustomersPage = () => {

  const [isSecondaryWindowVisible, setSecondaryWindowVisible] = useState(false);
  const [secodary_data, setSecodary_data] = useState({});
  const { userData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
  const [total_data, settotal_data] = useState([]);
  const {id} = useParams();
  const entity = "Customers";


  const handle_CloseSecodaryWindow = () =>{
    setSecondaryWindowVisible(false)
  }
  const handle_OpentoCreateSecodaryWindow = (data) =>{
    setSecodary_data(data)
    setSecondaryWindowVisible(true)
  }
  const handle_DeleteSecodaryWindow = (data) => {
    setSecondaryWindowVisible(false);
  };
  const handle_SaveSecodaryWindow = (_data) => {
    setSecondaryWindowVisible(false)
  };

  useEffect(() => {
        setLoading(true);
        let url = id ? '/'+entity.toLowerCase()+'/'+id : '/'+entity.toLowerCase()
        API_Request('GET', url)
        .then((result)=>{
          settotal_data(result.data);
        })
        .catch((error)=>{
          console.log(error);
        })
        .finally(()=>{
          setLoading(false);
        })
  }, [id]);
  if (total_data.length < 1) return (
    <Row>
    <Col span={24}>
        <h1>{entity}</h1><FileUploader title="Bulk import csv" Accounts={userData.accounts}onCSVUpload={handleUpload}></FileUploader>
    </Col>    
    <Spin spinning={TextTrackCue} style={{ position: 'fixed', right: 16, bottom: 16 }} />
    </Row>
  )

 return (
   (id !==undefined) ?
   <Row>
      <Col span={24}>
        <h1>{entity.slice(0, -1)}</h1>
      </Col>
      <Col span={12} offset={6}>
        {!loading && <FormBuilder entity={entity} formData={total_data[0]} formParams={dataTable_params.fieldsForm} />}
       </Col>
   </Row>
    :
    <Row>
        <Col span={24}>
            <h1>{entity}</h1><FileUploader title="Bulk import csv" Accounts={userData.accounts}onCSVUpload={handleUpload}></FileUploader>
        </Col>
        <Col span={12} offset={6}>
            
            <DataTable  loading={loading} data={total_data} params={dataTable_params} onCreateClick={handle_OpentoCreateSecodaryWindow}/>
            {(isSecondaryWindowVisible)&&  <FormSecondaryWindow title='Detail' data={secodary_data} onSave={handle_SaveSecodaryWindow} onClose={handle_CloseSecodaryWindow} onDelete={handle_DeleteSecodaryWindow}  />        }      
        </Col>
   </Row>
 );

};

export default CustomersPage;
