import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_Request } from '../Services/APIService';
import { SettingOutlined } from '@ant-design/icons';
import DataTable from '../Components/DataTable';
import { FormBuilder } from '../Components/FormBuilder';
import { Spin, Tooltip } from 'antd';
import { FormSecondaryWindow } from './../Components/FormSecondaryWindow';



const dataTable_params = {
  "entity": "users",
  "main": "username",
  "key": "id",
  "rows": 50,
  "fieldsForm":[
    {"name":"id","label":"Id","required":true,"width":"100"},
    {"name":"avatar","label":"Avatar","column":true,"width":"150"},
    {"name":"username","label":"description ","required":true},
    {"name":"role","label":"role ","required":true,"width":"150"},
    {"name":"tokenExpires","label":"tokenExpires"},
  ],
  "actions": [
    {
      icon: <Tooltip title="Details"><SettingOutlined style={{ fontSize: '20px'}} /></Tooltip>,
      link: "users"
    }
  ]
};


const UsersPage = () => {

  const [loading, setLoading] = useState(false);
  const [isSecondaryWindowEnabled, setSecondaryWindowEnabled] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const entity = "Users";

  
  const handle_SecondaryWindowEnabled_close = () => {
    setSecondaryWindowEnabled(false);
  };
  const handle_SecondaryWindowEnabled_open = () => {
    setSecondaryWindowEnabled(true);
  };


  const handle_SecondaryWindowSave = (updateData) => {
    setSecondaryWindowEnabled(false);
  }

  useEffect(() => {
        setLoading(true);
        API_Request('GET', id ? '/'+entity+'/'+id : '/'+entity)
        .then((result)=>{
          setData(result.data);
        })
        .catch((error)=>{
          console.log(error);
        })
        .finally(()=>{
          setLoading(false);
        })
  }, [id]);
  if (data === undefined  || (data.length < 1 && id !=='new')) {
    return <Spin spinning={TextTrackCue} style={{ position: 'fixed', right: 16, bottom: 16 }} />
  }

  return (
    (id !==undefined && id !=='new' ) ?
    <>
        <h1>{entity.slice(0, -1)} details</h1>
        {!loading && <>
                <FormBuilder entity={entity.toLowerCase()} formData={data} formParams={dataTable_params.fieldsForm} />
        </>}

    </>
     : 
            (id !=='new' ) &&
            <>
                <h1>{entity}</h1>
                <DataTable loading={loading} onChange={null} data={data} params={dataTable_params} onCreateClick={handle_SecondaryWindowEnabled_open}/>
                {(isSecondaryWindowEnabled)&& 
                            <FormSecondaryWindow  
                              title ={'Add'}
                              width = {'25%'}
                              onUpdate={handle_SecondaryWindowEnabled_close}
                              onSave={handle_SecondaryWindowSave}
                              onClose={handle_SecondaryWindowEnabled_close}
                              data={dataTable_params}
                /> }
            </>
  );
};

export default UsersPage;
