import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_Request } from '../Services/APIService';
import * as Icons from '@ant-design/icons';
import DataTable from '../Components/DataTable';

import { Spin } from 'antd';


const dataTable_params = {
  "entity": "sessions",
  "main": "uid",
  "key": "id",
  "create": false,
  "rows": 10,
  "fieldsForm":[
    {"name":"id","label":"id","required":true},
    {"name":"uid","label":"Session Id"},
    {"name":"type","label":"Session type","column":true,"width":"150"}, 
    {"name":"current_node"},
    {"name":"updatedAt"},
    {"name":"createdAt"}
  ],
  "actions": [
    {
      icon: <Icons.SettingOutlined  style={{ fontSize: '20px'}}  />,
      link: "sessions",
      title:"Settings"
    }
  ]
};

const dataTable_params_history = {
  "entity": "sessions",
  "main": "uid",
  "key": "uid",
  "rows": 100,
  "fieldsForm":[
    {"name":"type","label":"id","required":true},
    {"name":"author","label":"Session Id"},
    {"name":"event","label":"Session type","column":true,"width":"150"}, 
    {"name":"state"},
    {"name":"updatedAt"},
    {"name":"payload"},
    {"name":"variables"}
  ]
};



const SessionsPage = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const entity = "Sessions";


  const exportToCSV = () => {
   // const columns = ["type", "author", "event","state","updatedAt","payload","variables"]; 
   // const options = { 
   //     filename: 'sessions',
   //     fieldSeparator: ',',
   //     quoteStrings: '"',
   //     decimalSeparator: '.',
   //     showLabels: true, 
   //     useTextFile: false,
   //     useBom: true,
   //     useKeysAsHeaders: true,
   //     fields:  columns,
   //     headers: columns
   // };


   // const csvExporter = new ExportToCsv(options);
   // csvExporter.generateCsv((data.history));
  };

  useEffect(() => {
    setLoading(true);
    API_Request('GET',  id ? '/'+entity.toLowerCase()+'/'+id : '/'+entity.toLowerCase())
    .then((result)=>{
      setData(result.data);
    })
    .catch((error)=>{
      console.log(error);
    })
    .finally(()=>{
      setLoading(false);
    })
  }, [id]);
  if (data === undefined  || (data.length < 1)) {
    return <Spin spinning={TextTrackCue} style={{ position: 'fixed', right: 16, bottom: 16 }} />
  }
  return (
    (id !==undefined) ?
    <>
        <h2>Details</h2>
        <Icons.FileOutlined onClick={(e)=>{exportToCSV()}} >4576</Icons.FileOutlined>
        {data.history  && <DataTable loading={loading} data={data.history} params={dataTable_params_history}/>}
    </>
     :
     <>
     <h1>{entity}</h1>
     <DataTable loading={loading} data={data} params={dataTable_params}/>
     </>
  );

};

export default SessionsPage;
